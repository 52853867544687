<template>
  <PriorHeader />
  <div class="patient-information-form-wrapper">
    <div
      v-if="(selectedSubmission && selectedSubmission.status) || (orderInfo && orderInfo.status)"
      class="patient-information-page-title">
      <h1>
        {{ patientNameAndOrderKey }}
      </h1>
      <h1>{{ orderStatus }}</h1>
    </div>

    <GreenCirclePreloader v-if="showPreloader" />
    <div v-if="orderInfo">
      <PatientInformationFormPreview :order-info="orderInfo" />
      <div v-if="orderInfo.pharmacyInfo" class="form">
        <div class="patient-information-part">
          <h5>Pharmacy Information <span v-if="orderInfo.benefitType === 'Medical benefits'">(Optional)</span></h5>
          <hr class="bold-hr" />
          <p>
            By providing the pharmacy information, you authorize Do Prior Auth to contact the pharmacy of your choice on
            your behalf to report the status of your Prior Authorization as well as follow-up.
          </p>
          <PharmacyLookupComponent :pharmacy-selection="orderInfo.pharmacyInfo" />
        </div>
      </div>
      <div v-if="orderInfo.primaryDiagnosis" class="form">
        <div class="patient-information-part">
          <h5>Patient Diagnosis Information</h5>
          <hr class="bold-hr" />
          <DiagnosisSelectionPreviewComponent
            :primary-diagnosis="orderInfo.primaryDiagnosis"
            :other-diagnosis="orderInfo.otherDiagnosis" />
        </div>
      </div>
      <StepTherapiesPreviewComponent :drugs-tried="orderInfo.drugsTried" />
      <SupportingDocumentsPreviewComponent
        :order-key="orderInfo.orderKey"
        :medical-necessity="orderInfo.medicalNecessity"
        :medical-necessity-template="orderInfo.medicalNecessityTemplate"
        :lab-results="orderInfo.labResults"
        :patient-chart="orderInfo.patientChart"
        :guidelines="orderInfo.guidelines" />
      <QuestionsPreviewComponent
        :title="basicPlusQuestionsTitle"
        :checklist="orderInfo.basicPlusChecklist"
        :llm-prediction="orderInfo.basicPlusChecklistLlmScores"
        :original-payer-questions="orderInfo.basicPlusQuestions" />
      <QuestionsPreviewComponent
        :title="payerQuestionsTitle"
        :checklist="orderInfo.checklist"
        :llm-prediction="orderInfo.checklistLlmScores"
        :original-payer-questions="orderInfo.payerQuestions" />
      <ExpeditedReviewComponent
        :expedited-review-within24h="orderInfo.expeditedReviewWithin24h"
        :expedited-review-statement="orderInfo.expeditedReviewStatement" />
    </div>
  </div>
  <PriorFooter />
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useRoute } from "vue-router";
import { orderInfoService } from "@/services/orderInfoService";
import PriorHeader from "@/components/PriorHeader";
import PriorFooter from "@/components/PriorFooter";
import GreenCirclePreloader from "@/components/GreenCirclePreloader";
import PatientInformationFormPreview from "@/pages/PatientInformationFormPreview";
import DiagnosisSelectionPreviewComponent from "@/components/DiagnosisSelectionPreviewComponent.vue";
import StepTherapiesPreviewComponent from "@/components/StepTherapiesPreviewComponent.vue";
import ExpeditedReviewComponent from "@/components/ExpeditedReviewComponent.vue";
import SupportingDocumentsPreviewComponent from "@/components/SupportingDocumentsPreviewComponent.vue";
import QuestionsPreviewComponent from "@/components/QuestionsPreviewComponent";
import PharmacyLookupComponent from "@/components/PharmacyLookupComponent";
import { tryParseApiErrors } from "@/utils";
import { useSelectedSubmissionStore } from "@/stores/selectedSubmissionStore";
import { storeToRefs } from "pinia";

const { selectedSubmission } = storeToRefs(useSelectedSubmissionStore());

const route = useRoute();
const errors = ref([]);
const showPreloader = ref(false);
const orderInfo = ref(null);
const payerQuestionsTitle = "Insurance Coverage Criteria";
const basicPlusQuestionsTitle = "Additional Questions";

onMounted(() => {
  if (route.params.id) {
    getOrderInfo(route.params.id);
  }
});

onUnmounted(() => {
  clearSelectedSubmissionStorage();
});

const patientNameAndOrderKey = computed(() => {
  let patientNameAndOrderKey = "";
  if (selectedSubmission.value && selectedSubmission.value.status) {
    if (
      selectedSubmission.value.patientInfo &&
      selectedSubmission.value.patientInfo.firstName &&
      selectedSubmission.value.patientInfo.lastName
    ) {
      patientNameAndOrderKey = `${selectedSubmission.value.patientInfo.firstName} ${selectedSubmission.value.patientInfo.lastName} - ${selectedSubmission.value.orderKey}`;
    } else {
      patientNameAndOrderKey = `Patient Information Form Not Completed - ${selectedSubmission.value.orderKey}`;
    }
  } else if (orderInfo.value && orderInfo.value.status) {
    if (orderInfo.value.patientInfo && orderInfo.value.patientInfo.firstName && orderInfo.value.patientInfo.lastName) {
      patientNameAndOrderKey = `${orderInfo.value.patientInfo.firstName} ${orderInfo.value.patientInfo.lastName} - ${orderInfo.value.orderKey}`;
    } else {
      patientNameAndOrderKey = `Patient Information Form Not Completed - ${orderInfo.value.orderKey}`;
    }
  }
  return patientNameAndOrderKey;
});

const orderStatus = computed(() => {
  let orderStatus = "";
  if (selectedSubmission.value && selectedSubmission.value.status) {
    orderStatus = `Status: ${selectedSubmission.value.status}`;
  } else if (orderInfo.value && orderInfo.value.status) {
    orderStatus = `Status: ${orderInfo.value.status}`;
  }
  return orderStatus;
});

async function getOrderInfo(id) {
  showPreloader.value = true;
  try {
    let submissionInfo = await orderInfoService.getOrderDetails(id);
    orderInfo.value = submissionInfo.orderInfo;
  } catch (err) {
    errors.value = tryParseApiErrors(err);
  }
  showPreloader.value = false;
}

function clearSelectedSubmissionStorage() {
  selectedSubmission.value = {};
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
