<template>
  <div class="patient-information-form-wrapper">
    <div class="patient-information-page-title">
      <h1>{{ orderInfo.insuranceProvider }}</h1>
      <h1>
        {{ orderInfo.insurancePlanType }}
        <span v-if="orderInfo.insurancePlanType && orderInfo.insurancePlanType.length">|</span>
        {{ orderInfo.patientInfo.state }}
      </h1>
      <p>Personalize The Prior Authorization Steps For Your Patient.</p>
    </div>
  </div>
  <QuestionsSelectionComponent
    :title="basicPlusQuestionsTitle"
    :payer-questions="orderInfo.basicPlusQuestions"
    :checklist="orderInfo.basicPlusChecklist"
    :llm-prediction="orderInfo.basicPlusChecklistLlmScores"
    :form-button-clicked="formButtonClicked"
    @update-checklist="updateBasicPlusChecklistSelection" />
  <QuestionsSelectionComponent
    :title="payerQuestionsTitle"
    :payer-questions="orderInfo.payerQuestions"
    :checklist="orderInfo.checklist"
    :llm-prediction="orderInfo.checklistLlmScores"
    :form-button-clicked="formButtonClicked"
    @update-checklist="updateChecklistSelection" />
  <div class="patient-information-form-wrapper">
    <div class="form">
      <div>
        <div class="buttons-wrapper">
          <button
            class="return-button"
            :class="{ 'button-clicked': draftSavingInProgress }"
            :disabled="draftSavingInProgress"
            @click="savePartialOrderInfo">
            Save Draft
          </button>
          <button class="next-button" :disabled="draftSavingInProgress" @click="handleNextClicked">Next</button>
        </div>
      </div>
      <div class="errors-container">
        <span v-if="error" class="input-error-notification">
          Sorry, something went wrong. Please contact us at
          <a href="mailto:founders@lamarhealth.com"> founders@lamarhealth.com</a> or try again later
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useOrderInfoStore } from "@/stores/orderInfoStore";
import { orderInfoService } from "@/services/orderInfoService";
import { hasDuplicate } from "@/utils";

import QuestionsSelectionComponent from "@/components/QuestionsSelectionComponent.vue";

const emit = defineEmits(["nextClicked"]);
const { orderInfo } = storeToRefs(useOrderInfoStore());
const draftSavingInProgress = ref(false);
const error = ref(null);
const formButtonClicked = ref(false);

const payerQuestionsTitle = "Insurance Coverage Criteria";
const basicPlusQuestionsTitle = "Additional Questions";

onMounted(() => {
  orderInfo.value.formStep = "Payer Questions";
});

async function savePartialOrderInfo() {
  draftSavingInProgress.value = true;
  try {
    error.value = false;
    await orderInfoService.saveOrder(orderInfo.value.orderKey, orderInfo.value);
  } catch (err) {
    error.value = true;
  }
  setTimeout(() => {
    draftSavingInProgress.value = false;
  }, 1000);
}

const handleNextClicked = async () => {
  formButtonClicked.value = true;
  await new Promise((resolve) => setTimeout(resolve, 0));
  const missingInput = document.getElementsByClassName("input-error-notification");
  if (missingInput.length === 0) {
    await savePartialOrderInfo();
    emit("nextClicked");
  } else {
    setTimeout(() => {
      const missingInput = document.getElementsByClassName("input-error-notification");
      if (missingInput.length > 0) {
        window.scrollTo({
          bottom: missingInput[0],
          behavior: "smooth",
        });
      }
    }, 0);
  }
};

function updateChecklistSelection(newChecklistSelection, labResults, patientChartNotes, isDeselect = false) {
  if (isDeselect) {
    newChecklistSelection.forEach((unselectedItem) => {
      orderInfo.value.checklist = orderInfo.value.checklist.filter((item) => item.id !== unselectedItem.id);
    });
    if (labResults && labResults.length) {
      labResults.forEach((labResult) => {
        orderInfo.value.labResults = orderInfo.value.labResults.filter((item) => item.label !== labResult.label);
      });
    }
    if (patientChartNotes && patientChartNotes.length) {
      patientChartNotes.forEach((note) => {
        orderInfo.value.patientChart = orderInfo.value.patientChart.filter((item) => item.label !== note.label);
      });
    }
  } else {
    let updatedChecklist = [];
    newChecklistSelection.forEach((selectedItem) => {
      updatedChecklist = orderInfo.value.checklist.map((item) => {
        if (item.id === selectedItem.id) {
          return selectedItem;
        }
        return item;
      });
      if (!updatedChecklist.find((item) => item.id === selectedItem.id)) {
        updatedChecklist.push(selectedItem);
      }
    });
    orderInfo.value.checklist = updatedChecklist;
    if (labResults && labResults.length) {
      labResults.forEach((labResult) => {
        if (!hasDuplicate(orderInfo.value.labResults, labResult)) {
          orderInfo.value.labResults.push({ label: labResult.label, links: [] });
        }
      });
    }
    if (patientChartNotes && patientChartNotes.length) {
      patientChartNotes.forEach((note) => {
        if (!hasDuplicate(orderInfo.value.patientChart, note)) {
          orderInfo.value.patientChart.push({ label: note.label, links: [] });
        }
      });
    }
  }
}

function updateBasicPlusChecklistSelection(newChecklistSelection, labResults, patientChartNotes, isDeselect = false) {
  if (isDeselect) {
    newChecklistSelection.forEach((unselectedItem) => {
      orderInfo.value.basicPlusChecklist = orderInfo.value.basicPlusChecklist.filter(
        (item) => item.id !== unselectedItem.id
      );
    });
  } else {
    let updatedChecklist = [];
    newChecklistSelection.forEach((selectedItem) => {
      updatedChecklist = orderInfo.value.basicPlusChecklist.map((item) => {
        if (item.id === selectedItem.id) {
          return selectedItem;
        }
        return item;
      });
      if (!updatedChecklist.find((item) => item.id === selectedItem.id)) {
        updatedChecklist.push(selectedItem);
      }
    });
    orderInfo.value.basicPlusChecklist = updatedChecklist;
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
