<template>
  <div v-if="props.checklist && props.checklist.length" class="patient-information-form-wrapper">
    <div class="form">
      <div class="light-background-section">
        <h5>{{ props.title }}</h5>
        <hr class="light-hr" />
        <div v-for="(group, gIndex) in groupedQuestions" :key="group.header || gIndex">
          <hr v-if="gIndex !== 0 && group.header && groupHasVisibleQuestions(group)" class="light-hr" />
          <h3 v-if="group.header && groupHasVisibleQuestions(group)">{{ group.header }}</h3>
          <div v-for="currentRequirement in group.questions" :key="currentRequirement.id">
            <div v-if="!currentRequirement.invisibleInPortal">
              <p class="accented-paragraph">
                {{ currentRequirement.label }}
              </p>
              <input
                v-model="currentRequirement.options[0].label"
                :type="currentRequirement.options[0].nodeType"
                :placeholder="currentRequirement.label"
                readonly="true" />
              <div v-if="currentRequirement.llmPrediction">
                <p class="llm-prediction">
                  <span v-if="currentRequirement.llmPrediction.isHumanReviewRequested" style="color: #ff6347">
                    Review Needed
                  </span>
                  <span v-if="currentRequirement.llmPrediction.context">
                    Context: {{ currentRequirement.llmPrediction.context }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

const payerQuestions = ref([]);

const props = defineProps({
  checklist: {
    type: Array,
    default() {
      return [];
    },
  },
  llmPrediction: {
    type: Array,
    default() {
      return [];
    },
  },
  originalPayerQuestions: {
    type: Array,
    default() {
      return [];
    },
  },
  title: {
    type: String,
    default: "",
  },
});

onMounted(() => {
  for (const requirement of props.checklist) {
    let payerQuestion = requirement;
    if (props.llmPrediction && props.llmPrediction.length > 0) {
      payerQuestion.llmPrediction = props.llmPrediction.find((prediction) => prediction.answerId === requirement.id);
    }
    let originalQuestion = props.originalPayerQuestions.find(
      (originalPayerQuestion) => originalPayerQuestion.requirementRuleName === requirement.id
    );
    payerQuestion.invisibleInPortal = originalQuestion ? originalQuestion.invisibleInPortal : false;
    payerQuestion.sectionHeader = originalQuestion ? originalQuestion.sectionHeader : null;
    payerQuestions.value.push(payerQuestion);
  }
});

const groupedQuestions = computed(() => {
  const map = new Map();

  payerQuestions.value.forEach((requirement) => {
    const header = requirement.sectionHeader || "no-header";
    if (!map.has(header)) {
      map.set(header, []);
    }
    map.get(header).push(requirement);
  });

  return Array.from(map, ([header, questions]) => ({
    header: header === "no-header" ? null : header,
    questions,
  }));
});

function isRequirementApplicable(requirement) {
  return !requirement.invisibleInPortal;
}

function groupHasVisibleQuestions(group) {
  return group.questions.some((req) => isRequirementApplicable(req));
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
