<template>
  <div v-if="payerQuestions.length" class="patient-information-form-wrapper">
    <div class="form">
      <div class="light-background-section">
        <h5>{{ props.title }}</h5>
        <hr class="light-hr" />
        <div v-for="(group, gIndex) in groupedQuestions" :key="group.header || gIndex">
          <hr v-if="gIndex !== 0 && group.header && groupHasVisibleQuestions(group)" class="light-hr" />
          <h3 v-if="group.header && groupHasVisibleQuestions(group)">{{ group.header }}</h3>
          <div
            v-for="currentRequirement in group.questions"
            :key="currentRequirement.requirementRuleName"
            :class="{ hide: !isRequirementApplicable(currentRequirement) }">
            <p class="accented-paragraph">{{ currentRequirement.label }}</p>
            <div
              v-if="
                currentRequirement.requirementOptions[0].nodeType === 'radio' ||
                currentRequirement.requirementOptions[0].nodeType === 'checkbox'
              ">
              <select
                :id="currentRequirement.requirementRuleName"
                v-model="currentRequirement.selectedOption"
                class="custom-select-arrow"
                @change="setSessionRequirement(currentRequirement)">
                <option
                  v-for="option in currentRequirement.requirementOptions"
                  :key="option.optionRuleName"
                  :value="{
                    id: option.optionRuleName,
                    label: option.label,
                  }">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div v-else-if="currentRequirement.requirementOptions[0].nodeType === 'textarea'">
              <textarea
                :id="`${currentRequirement.requirementOptions[0].optionRuleName}`"
                v-model="currentRequirement.selectedOption.label"
                class="textarea"
                rows="8"
                :placeholder="currentRequirement.requirementOptions[0].label"
                @change="setSessionRequirement(currentRequirement)"></textarea>
            </div>
            <div v-else>
              <input
                :id="`${currentRequirement.requirementOptions[0].optionRuleName}`"
                v-model="currentRequirement.selectedOption.label"
                :type="currentRequirement.requirementOptions[0].nodeType"
                :placeholder="currentRequirement.requirementOptions[0].label"
                @change="setSessionRequirement(currentRequirement)" />
            </div>
            <div v-if="currentRequirement.llmPrediction">
              <p class="llm-prediction">
                <span v-if="currentRequirement.llmPrediction.isHumanReviewRequested" style="color: #ff6347">
                  Review Needed
                </span>
                <span v-if="currentRequirement.llmPrediction.context">
                  Context: {{ currentRequirement.llmPrediction.context }}
                </span>
              </p>
            </div>
            <span
              v-if="
                isRequirementApplicable(currentRequirement) &&
                props.formButtonClicked &&
                (!currentRequirement.selectedOption.id ||
                  (currentRequirement.selectedOption.id &&
                    (currentRequirement.requirementOptions[0].nodeType === 'text' ||
                      currentRequirement.requirementOptions[0].nodeType === 'textarea' ||
                      currentRequirement.requirementOptions[0].nodeType === 'number' ||
                      currentRequirement.requirementOptions[0].nodeType === 'date') &&
                    !currentRequirement.selectedOption.label))
              "
              class="input-error-notification">
              Please answer this question to the best of your ability. Failing to do so may result in a delay in
              processing your request.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

const payerQuestions = ref([]);
const sessionRequirements = ref([]);
const sessionRequirementOptions = ref([]);
const sessionLabResults = ref([]);
const sessionPatientChartNotes = ref([]);

const emit = defineEmits(["updateChecklist"]);

const props = defineProps({
  payerQuestions: {
    type: Array,
    default() {
      return [];
    },
  },
  checklist: {
    type: Array,
    default() {
      return [];
    },
  },
  llmPrediction: {
    type: Array,
    default() {
      return [];
    },
  },
  formButtonClicked: {
    type: Boolean,
    default() {
      return false;
    },
  },
  title: {
    type: String,
    default: "",
  },
});

onMounted(async () => {
  let payerQuestionsTemp = [];
  if (props.payerQuestions && props.payerQuestions.length > 0) {
    for (const requirement of props.payerQuestions) {
      let payerQuestion = requirement;
      const checklistSelection = props.checklist.find(
        (selection) => selection.id === requirement.requirementRuleName && selection.options[0].label
      );
      if (checklistSelection) {
        payerQuestion.selectedOption =
          checklistSelection.options.length > 1 ? checklistSelection.options : checklistSelection.options[0];
        payerQuestion.originalSelectedOption =
          checklistSelection.options.length > 1 ? checklistSelection.options : checklistSelection.options[0];
        sessionRequirements.value.push(requirement.requirementRuleName);
        sessionRequirementOptions.value.push(requirement.selectedOption.id);
        if (props.llmPrediction && props.llmPrediction.length > 0) {
          payerQuestion.llmPrediction = props.llmPrediction.find(
            (prediction) => prediction.answerId === requirement.requirementRuleName
          );
        }
      } else {
        if (
          payerQuestion.requirementOptions[0].nodeType === "radio" ||
          payerQuestion.requirementOptions[0].nodeType === "checkbox"
        ) {
          payerQuestion.selectedOption = { id: null, label: null };
          payerQuestion.llmPrediction = null;
        }
        if (
          payerQuestion.requirementOptions[0].nodeType === "text" ||
          payerQuestion.requirementOptions[0].nodeType === "textarea" ||
          payerQuestion.requirementOptions[0].nodeType === "number" ||
          payerQuestion.requirementOptions[0].nodeType === "date"
        ) {
          payerQuestion.selectedOption = {
            id: payerQuestion.requirementOptions[0].optionRuleName,
            label: null,
          };
          payerQuestion.llmPrediction = null;
        } else {
          payerQuestion.selectedOption = { id: null, label: null };
          payerQuestion.llmPrediction = null;
        }
      }
      payerQuestionsTemp.push(requirement);
    }
    payerQuestions.value.push(...sortRequirements(payerQuestionsTemp));
  }
});

function sortRequirements(requirements) {
  const sortedRequirements = [];
  if (requirements && requirements.length > 0 && requirements[0].displayOrder) {
    requirements.sort((a, b) => a.displayOrder - b.displayOrder);
    sortedRequirements.push(...requirements);
    return sortedRequirements;
  }
  const requirementsWithRules = [];
  const requirementsWithNoRules = [];
  for (const requirement of requirements) {
    if (requirement.requirementRuleSet && requirement.requirementRuleSet.length > 0) {
      requirementsWithRules.push(requirement);
    } else {
      requirementsWithNoRules.push(requirement);
    }
  }
  requirementsWithRules.sort((a, b) => {
    if (a.requirementRuleSet.includes(b.requirementRuleName)) {
      return 1;
    } else if (b.requirementRuleSet.includes(a.requirementRuleName)) {
      return -1;
    } else {
      return 0;
    }
  });
  sortedRequirements.push(...requirementsWithNoRules);
  sortedRequirements.push(...requirementsWithRules);
  return sortedRequirements;
}

function unSetDependentSelection(unselectedRequirement, unselectedOption) {
  const unSetRequirements = [];
  const unSetSessionLabResults = [];
  const unSetSessionPatientChartNotes = [];
  sessionRequirementOptions.value = sessionRequirementOptions.value.filter(
    (sessionOption) => sessionOption !== unselectedOption.id
  );
  sessionRequirements.value = sessionRequirements.value.filter(
    (sessionRequirement) => sessionRequirement !== unselectedRequirement.requirementRuleName
  );
  unSetRequirements.push({
    id: unselectedRequirement.requirementRuleName,
  });
  const unselectedOptionFull = unselectedRequirement.requirementOptions.find(
    (option) => option.optionRuleName === unselectedOption.id
  );
  if (unselectedOptionFull.labResults && unselectedOptionFull.labResults.length) {
    unselectedOptionFull.labResults.forEach((labResult) => {
      if (sessionLabResults.value.includes(labResult)) {
        sessionLabResults.value.splice(sessionLabResults.value.indexOf(labResult), 1);
        unSetSessionLabResults.push(labResult);
      }
    });
  }
  if (unselectedOptionFull.patientChartNotes && unselectedOptionFull.patientChartNotes.length) {
    unselectedOptionFull.patientChartNotes.forEach((patientChartNote) => {
      if (sessionPatientChartNotes.value.includes(patientChartNote)) {
        sessionPatientChartNotes.value.splice(sessionPatientChartNotes.value.indexOf(patientChartNote), 1);
        unSetSessionPatientChartNotes.push(patientChartNote);
      }
    });
  }
  for (const requirement of payerQuestions.value) {
    if (
      requirement.requirementRuleSet &&
      requirement.requirementRuleSet.includes(unselectedRequirement.requirementRuleName)
    ) {
      for (const option of requirement.requirementOptions) {
        if (option.optionRuleSet && option.optionRuleSet.includes(unselectedOption.id)) {
          sessionRequirementOptions.value = sessionRequirementOptions.value.filter(
            (sessionOption) => sessionOption !== option.optionRuleName
          );
          sessionRequirements.value = sessionRequirements.value.filter(
            (sessionRequirement) => sessionRequirement !== requirement.requirementRuleName
          );
          if (option.labResults && option.labResults.length) {
            option.labResults.forEach((labResult) => {
              if (sessionLabResults.value.includes(labResult)) {
                sessionLabResults.value.splice(sessionLabResults.value.indexOf(labResult), 1);
                unSetSessionLabResults.push(labResult);
              }
            });
          }
          if (option.patientChartNotes && option.patientChartNotes.length) {
            option.patientChartNotes.forEach((patientChartNote) => {
              if (sessionPatientChartNotes.value.includes(patientChartNote)) {
                sessionPatientChartNotes.value.splice(sessionPatientChartNotes.value.indexOf(patientChartNote), 1);
                unSetSessionPatientChartNotes.push(patientChartNote);
              }
            });
          }
          if (
            requirement.requirementOptions[0].nodeType === "radio" ||
            requirement.requirementOptions[0].nodeType === "checkbox"
          ) {
            requirement.selectedOption = { id: null, label: null };
          }
          if (
            requirement.requirementOptions[0].nodeType === "text" ||
            requirement.requirementOptions[0].nodeType === "textarea" ||
            requirement.requirementOptions[0].nodeType === "number" ||
            requirement.requirementOptions[0].nodeType === "date"
          ) {
            requirement.selectedOption = {
              id: requirement.requirementOptions[0].optionRuleName,
              label: null,
            };
          } else {
            requirement.selectedOption = { id: null, label: null };
          }
          unSetRequirements.push({
            id: requirement.requirementRuleName,
          });
        }
      }
    }
  }
  emit("updateChecklist", unSetRequirements, unSetSessionLabResults, unSetSessionPatientChartNotes, true);
}

function setSessionRequirement(requirement) {
  if (sessionRequirements.value.includes(requirement.requirementRuleName) && requirement.originalSelectedOption) {
    unSetDependentSelection(requirement, requirement.originalSelectedOption);
  }
  if (!sessionRequirements.value.includes(requirement.requirementRuleName)) {
    sessionRequirements.value.push(requirement.requirementRuleName);
  }
  if (!sessionRequirementOptions.value.includes(requirement.selectedOption.id)) {
    if (
      requirement.requirementOptions[0].nodeType === "radio" ||
      requirement.requirementOptions[0].nodeType === "checkbox"
    ) {
      sessionRequirementOptions.value = sessionRequirementOptions.value.filter(
        (option) => !requirement.requirementOptions.map((option) => option.optionRuleName).includes(option)
      );
    }
    sessionRequirementOptions.value.push(requirement.selectedOption.id);
    const requirementOption = requirement.requirementOptions.find(
      (option) => option.optionRuleName === requirement.selectedOption.id
    );
    if (requirementOption.labResults && requirementOption.labResults.length) {
      requirementOption.labResults.forEach((labResult) => {
        sessionLabResults.value.push(labResult);
      });
    }
    if (requirementOption.patientChartNotes && requirementOption.patientChartNotes.length) {
      requirementOption.patientChartNotes.forEach((patientChartNote) => {
        sessionPatientChartNotes.value.push(patientChartNote);
      });
    }
  }
  requirement.originalSelectedOption = requirement.selectedOption;
  emit(
    "updateChecklist",
    [
      {
        id: requirement.requirementRuleName,
        label: requirement.label,
        options: [
          {
            id: requirement.selectedOption.id,
            label: requirement.selectedOption.label,
          },
        ],
      },
    ],
    sessionLabResults.value,
    sessionPatientChartNotes.value
  );
}

function isRequirementApplicable(requirement) {
  // Questions is not applicable if it is invisible in portal
  if (requirement.invisibleInPortal) {
    return false;
  }
  // If question has already been answered, it needs to be visible
  if (sessionRequirements.value.includes(requirement.requirementRuleName)) {
    return true;
  }
  // if question has no rules attached, it needs to be visible
  if (!requirement.requirementRuleSet || requirement.requirementRuleSet.length === 0) {
    return true;
  }
  // Check if there are any answers that fail the rule check (existing answers do not include the answer that is required in the rules)
  let optionsFailedRuleCheck = [];
  for (const option of requirement.requirementOptions) {
    for (const optionRule of option.optionRuleSet) {
      if (!sessionRequirementOptions.value.includes(optionRule)) {
        optionsFailedRuleCheck.push(option);
      }
    }
  }
  // Copy to JSON to avoid modifying the original object saved on the record
  let tempRequirement = JSON.parse(JSON.stringify(requirement));
  for (const option of optionsFailedRuleCheck) {
    tempRequirement.requirementOptions.splice(
      tempRequirement.requirementOptions.findIndex((o) => o.optionRuleName === option.optionRuleName),
      1
    );
  }
  // if there are no options left, the question is not applicable
  if (tempRequirement.requirementOptions.length === 0) {
    return false;
  } else {
    // Check elective rules for the options
    let electiveOptions = [];
    let electiveOptionsRuleSets = [];
    for (const option of tempRequirement.requirementOptions) {
      const electiveOptionRuleSet = option.electiveOptionRuleSet;
      if (electiveOptionRuleSet && electiveOptionRuleSet.length > 0) {
        for (const rule of electiveOptionRuleSet) {
          electiveOptionsRuleSets.push(rule);
        }
      }
    }
    // if there are no elective rules, the question is applicable
    if (electiveOptionsRuleSets.length === 0) {
      return true;
    }
    // Check if any of the elective rules are met
    for (const electiveOptionRuleSet of electiveOptionsRuleSets) {
      for (const electiveOption of electiveOptionRuleSet) {
        if (sessionRequirementOptions.value.includes(electiveOption)) {
          electiveOptions.push(electiveOption);
        }
      }
    }
    if (electiveOptions.length === 0) {
      return false;
    }
    if (electiveOptions.length === electiveOptionsRuleSets.length) {
      return true;
    }
  }
}

const groupedQuestions = computed(() => {
  const map = new Map();

  payerQuestions.value.forEach((requirement) => {
    const header = requirement.sectionHeader || "no-header";
    if (!map.has(header)) {
      map.set(header, []);
    }
    map.get(header).push(requirement);
  });

  return Array.from(map, ([header, questions]) => ({
    header: header === "no-header" ? null : header,
    questions,
  }));
});

function groupHasVisibleQuestions(group) {
  return group.questions.some((req) => isRequirementApplicable(req));
}
</script>

<style lang="scss" scoped>
@import "../styles/pages/_patient-information-form.scss";
</style>
