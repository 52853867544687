import api from "@/services/api";

const { post, put, get, delete: del } = api.getPrefixedMethods("orderinfo");

export const orderInfoService = {
  submitOrderInfo(data) {
    return post("", data);
  },
  validateAccess(data) {
    return post("access-initiated-prior-auth", data);
  },
  getListOfOrders(index) {
    return get("", { params: { page: index } });
  },
  uploadDecisionDocument(orderKey, data) {
    return put(`upload-decision-document/${orderKey}`, data);
  },
  searchOrders(searchTerm, index) {
    return post("search", searchTerm, { params: { page: index } });
  },
  getOrderDetails(id) {
    return get(`submission/${id}`);
  },
  pullAutofillingData(orderKey, data) {
    return post(`submit-autofill-data/${orderKey}`, data);
  },
  getAvailableAddressBooks() {
    return get("available-address-books");
  },
  createEmptyBenefitVerificationRequest(data) {
    return post("create-benefit-verification", data);
  },
  saveBenefitVerificationRequest(orderKey, data) {
    return put(`save-benefit-verification/${orderKey}`, data);
  },
  createEmptyOrder(data) {
    return post("create-empty-order", data);
  },
  createNewOrder(data) {
    return post("create-order", data);
  },
  saveOrder(orderKey, data) {
    return put(`save-order/${orderKey}`, data);
  },
  submitPdfForm(data) {
    return post("pdf-submission", data);
  },
  getPdfSubmissionDetails(id) {
    return get(`pdf-submission/${id}`);
  },
  updatePdfSubmission(id, data) {
    return put(`pdf-submission/${id}`, data);
  },
  fetchPatientRecords(orderKey) {
    return get(`patient-records/${orderKey}`);
  },
  deleteOrder(orderKey) {
    return del(`submission/${orderKey}`);
  },
  getSupportingDocumentsPdf(orderKey) {
    return get(`supporting-documents-pdf/${orderKey}`, {
      responseType: "blob",
      headers: { "Content-Type": "application/pdf" },
      _returnFullResponse: true,
      _skipPropsAdaptation: true,
    });
  },
};
